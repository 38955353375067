import { createStyles } from "@mantine/core";
import { mediaBreakpoints } from "utils/breakpoints";

const horizontalIndent = 50;
const mobileHorizontalIndent = 30;

export const useFirstScreenStyles = createStyles((theme, _params, getRef) => ({
  overflowHidden: {
    overflow: "hidden",
  },

  header: {
    padding: `38px 0px 5px 0px`,
    position: "sticky",
    top: 0,
    zIndex: 100,
    marginBottom: 10,
    background: "white",
    transition: "0.3s",
    [mediaBreakpoints.mobile]: {
      padding: `18px 0px 6px 0px`,
      marginBottom: 5,
    },
  },
  headerCenter: {
    padding: `0px ${horizontalIndent}px`,
    display: "flex",
    flexWrap: "wrap",
    [mediaBreakpoints.tabletAndMobile]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
    [mediaBreakpoints.mobile]: {
      padding: `0 ${mobileHorizontalIndent}rem`,
    },
  },

  menu: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
    [mediaBreakpoints.tabletAndMobile]: {
      gap: 14,
    },
  },
  menuLink: {
    fontSize: 16,
    letterSpacing: "0.07em",
    color: "inherit",
    textDecoration: "none",
    [mediaBreakpoints.tabletAndMobile]: {
      width: "100%",
    },
  },
  desktopMenu: {
    [mediaBreakpoints.tabletAndMobile]: {
      display: "none",
    },
  },
  mobileMenu: {
    display: "none",

    [mediaBreakpoints.tabletAndMobile]: {
      display: "block",
    },
  },

  section: {
    padding: "10px 0px 40px 0px",
    [mediaBreakpoints.tablet]: {
      padding: "10px 0px 40rem 0px",
    },
    [mediaBreakpoints.mobile]: {
      padding: "10px 0rem 40px 0rem",
    },
  },
  logo: {
    textDecoration: "none",
    color: "inherit",
    marginRight: 32,
    display: "block",
    fontWeight: 700,
    [mediaBreakpoints.tabletAndMobile]: {
      lineHeight: 1,
    },
  },
  center: {
    margin: "0 auto",
    maxWidth: 1200,
    [mediaBreakpoints.tablet]: {
      maxWidth: "1060rem",
    },
    [mediaBreakpoints.mobile]: {
      padding: `0 25rem`,
    },
  },
  centerShell: {
    position: "relative",
    padding: `50px ${horizontalIndent}px`,
    [mediaBreakpoints.tablet]: {
      padding: `50rem ${horizontalIndent}rem`,
    },
    [mediaBreakpoints.mobile]: {
      padding: `50rem ${mobileHorizontalIndent}rem`,
    },
  },
  content: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  firstColumn: {
    width: "40%",
    [mediaBreakpoints.mobile]: {
      width: "57%",
      display: "flex",
      alignContent: "space-between",
      flexWrap: "wrap",
    },
  },
  secondColumn: {
    width: 275,
    [mediaBreakpoints.tablet]: {
      width: `262rem`,
    },
    [mediaBreakpoints.mobile]: {
      width: "41%",
    },
  },
  thirdColumn: {
    width: 275,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [mediaBreakpoints.tablet]: {
      width: `275rem`,
      gap: "16rem",
    },
    [mediaBreakpoints.mobile]: {
      width: "100%",
      gap: 12,
      marginTop: 15,
    },
  },
  headline: {
    fontFamily: "Seed",
    textTransform: "uppercase",
    fontSize: 59,
    lineHeight: "122%",
    marginBottom: "71px",
    [mediaBreakpoints.tablet]: {
      fontSize: "52rem",
      marginBottom: "99rem",
    },
    [mediaBreakpoints.mobile]: {
      fontSize: `36rem`,
      marginTop: 20,
      marginBottom: 0,
    },
  },
  headlineUnderline: {
    position: "relative",
    display: "inline-block",
    "& svg": {
      width: "100%",
      position: "absolute",
      left: 0,
      top: "calc(100% - 10px)",
    },
  },

  chat: {
    background: "none",
    border: "none",
    position: "relative",
    cursor: "pointer",
    width: "100%",
    display: "block",
    textDecoration: "none",
    "&:hover * path": {
      fill: "black",
    },
    "& * path": {
      transition: "0.2s",
    },
    "&:hover > span": {
      color: "white",
    },
    [mediaBreakpoints.mobile]: {},
  },
  chatText: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 29,
    letterSpacing: "0.07em",
    fontWeight: 700,
    transition: "0.2s",
    color: "black",
    [mediaBreakpoints.tablet]: {
      fontSize: "29rem",
    },
    [mediaBreakpoints.mobile]: {
      fontSize: "33rem",
    },
  },
  chatImage: {
    display: "block",
    width: "100%",
  },

  advantage: {
    position: "relative",
    padding: `15px 23px`,
    [mediaBreakpoints.tablet]: {
      padding: `15rem 23rem`,
    },
    [mediaBreakpoints.mobile]: {
      padding: `7px 12px`,
    },
  },
  advantageText: {
    position: "relative",
    zIndex: 1,
    lineHeight: "162%",
    fontSize: 32,
    fontFamily: "Seed",
    textTransform: "uppercase",
    [mediaBreakpoints.tablet]: {
      fontSize: "32rem",
    },
    [mediaBreakpoints.mobile]: {
      fontSize: 21,
      "& > br": {
        display: "none",
      },
    },
  },

  avatarFirstLevel: {
    padding: 15,
    position: "relative",
    height: 450,
    [mediaBreakpoints.tablet]: {
      padding: "15rem",
      height: "440rem",
    },
    [mediaBreakpoints.mobile]: {
      height: `${300}rem`,
      padding: 7,
    },
  },
  avatarSecondLevel: {
    position: "relative",
    height: "100%",
    [mediaBreakpoints.mobile]: {
      width: "100%",
    },
  },

  more: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    textDecoration: "none",
    "&:hover": {
      opacity: "0.5",
    },
    [mediaBreakpoints.tablet]: {
      gap: "12rem",
    },
  },
  moreIcon: {
    display: "block",
    [mediaBreakpoints.tablet]: {
      width: "12rem",
    },
  },
  moreText: {
    fontSize: 16,
    letterSpacing: "0.07em",
    lineHeight: "100%",
    color: "#333",
    [mediaBreakpoints.tablet]: {
      fontSize: "16rem",
    },
  },

  description: {
    padding: 24,
    maxWidth: 850,
    margin: `0 ${horizontalIndent}px 50px ${horizontalIndent}px`,
    position: "relative",
    [mediaBreakpoints.tablet]: {
      padding: "24rem",
      maxWidth: "850rem",
    },
    [mediaBreakpoints.mobile]: {
      margin: `0 0 50px 0rem`,
    },
  },
  descriptionShell: {
    position: "relative",
    zIndex: 2,
  },
  descriptionTitle: {
    marginBottom: 21,
    fontSize: 31,
    fontFamily: "Seed",
    [mediaBreakpoints.tablet]: {
      marginBottom: "21rem",
      fontSize: "31rem",
    },
    [mediaBreakpoints.mobile]: {
      fontSize: 22,
    },
  },
  descriptionLines: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    "& * a": {
      color: "inherit",
    },
    "& * a:hover": {
      opacity: "0.5",
    },
    [mediaBreakpoints.tablet]: {
      gap: "15rem",
    },
    [mediaBreakpoints.mobile]: {
      gap: 11,
    },
  },
  descriptionLine: {
    fontSize: 21,
    lineHeight: "129%",
    [mediaBreakpoints.tablet]: {
      fontSize: "21rem",
    },
    [mediaBreakpoints.mobile]: {
      fontSize: 17,
      lineHeight: "142%",
    },
  },
}));
