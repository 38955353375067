import { memo } from "react";


import { technologiesData } from "./technologiesData";
import { useTechnologiesScreenStyles } from "./technologiesScreenStyles";

export const TechnologiesScreenMemo = memo(TechnologiesScreen, () => true);
function TechnologiesScreen() {
    const { classes } = useTechnologiesScreenStyles();
    return (
        <section id={"technologies"} className={classes.section}>
            <div className={classes.shell}>
                <div className={classes.center}>
                    <h2 className={classes.headline}>Технологии</h2>
                    <div className={classes.techologies}>
                        {technologiesData.map((technology, index) =>
                            <Technology key={index} technology={technology} />
                        )}

                    </div>

                </div>
            </div>
        </section>
    );
}

function Technology({ technology }: { technology: (typeof technologiesData)[0] }) {
    const { classes } = useTechnologiesScreenStyles();


    return (
        <h3 className={classes.techology}>
            {technology.name}
        </h3>
    );
}
