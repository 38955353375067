import Head from "next/head";
import { useRouter } from "next/router";


import { useMainPageStyles } from "hooks/useMainPageStyles";
import { ContactScreen } from "screens/ContactScreen/ContactScreen";
import { FirstScreen } from "screens/FirstScreen/FirstScreen";
import { PortfolioScreen } from "screens/PortfolioScreen/PortfolioScreen";
import { ReasonsScreen } from "screens/ReasonsScreen/ReasonsScreen";
import { ReviewsScreenMemo } from "screens/ReviewsScreen/ReviewsScreen";
import { TechnologiesScreenMemo } from "screens/TechnologiesScreen/TechnologiesScreen";

export default function IndexPage() {
  // const createSite = trpc.useMutation('user.createSite', {
  //   onError: error => {
  //     showNotification({
  //       title: error.message,
  //       message: '',
  //       color: 'red',
  //     })
  //   },
  // })

  const router = useRouter();

  // const user = trpc.useQuery(
  //   [
  //     'user.getUserSites',
  //     {
  //       isArchive: false,
  //     },
  //   ],
  //   {
  //     refetchInterval: 500,
  //   }
  // )

  const { classes, cx } = useMainPageStyles();

  const countOfScreens = 7;

  return (
    <>
      <Head>
        <title>Кододел - Лобанов Андрей</title>
      </Head>

      <FirstScreen />
      <PortfolioScreen />
      <ReasonsScreen />
      <ReviewsScreenMemo />
      <TechnologiesScreenMemo />
      <ContactScreen />
    </>
  );
}
