import { createStyles } from "@mantine/core";
import { mediaBreakpoints } from "utils/breakpoints";

export const useTechnologiesScreenStyles = createStyles(
  (theme, _params, getRef) => ({
    section: {
      padding: "33px 0 0 0",
    },
    shell: {
      border: "none",
      borderTop: "1px solid #DADADA",
      borderBottom: "1px solid #DADADA",
      [mediaBreakpoints.mobile]: {
        padding: "0 15px",
      },
    },
    center: {
      maxWidth: 1200,
      margin: "0 auto",
      borderLeft: "1px solid #DADADA",
      borderRight: "1px solid #DADADA",
      padding: "70px 25px 70px 25px",
      [mediaBreakpoints.mobile]: {
        padding: "22px",
      },
    },
    headline: {
      fontSize: 49,
      color: "black",
      letterSpacing: "0.07em",
      lineHeight: "100%",
      marginBottom: 24,
      textTransform: "uppercase",
      [mediaBreakpoints.mobile]: {
        fontSize: 29,
      },
    },
    techologies: {
      display: "flex",
      flexWrap: "wrap",
      gap: 18,
    },
    techology: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: "0.04em",
    },
  })
);
