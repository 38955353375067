type Technology = {
  name: string;
  link: string;
  versions: number[];
};

export const technologiesData: Technology[] = [
  {
    name: "typescript",
    link: "",
    versions: [],
  },
  {
    name: "dom api",
    link: "",
    versions: [],
  },
  {
    name: "react.js",
    link: "",
    versions: [],
  },
  {
    name: "next.js",
    link: "",
    versions: [],
  },
  {
    name: "mantine",
    link: "",
    versions: [],
  },
  {
    name: "git",
    link: "",
    versions: [],
  },
  {
    name: "history api",
    link: "",
    versions: [],
  },
  {
    name: "location api",
    link: "",
    versions: [],
  },
  {
    name: "openstreetmap",
    link: "",
    versions: [],
  },
  {
    name: "prisma",
    link: "",
    versions: [],
  },
  {
    name: "emotion",
    link: "",
    versions: [],
  },
  {
    name: "bem",
    link: "",
    versions: [],
  },
  {
    name: "yandex map",
    link: "",
    versions: [],
  },
  {
    name: "react-router-dom",
    link: "",
    versions: [],
  },
  {
    name: "webrtc",
    link: "",
    versions: [],
  },
  {
    name: "antd",
    link: "",
    versions: [],
  },

  {
    name: "dnd-kit",
    link: "",
    versions: [],
  },
  {
    name: "juggle/resize-observer",
    link: "",
    versions: [],
  },
  {
    name: "trpc",
    link: "",
    versions: [],
  },
  {
    name: "axios",
    link: "",
    versions: [],
  },
  {
    name: "cookies",
    link: "",
    versions: [],
  },
  {
    name: "moment",
    link: "",
    versions: [],
  },
  {
    name: "prettier",
    link: "",
    versions: [],
  },
  {
    name: "socket.io",
    link: "",
    versions: [],
  },
  {
    name: "zod",
    link: "",
    versions: [],
  },
  {
    name: "zustand",
    link: "",
    versions: [],
  },
  {
    name: "node.js",
    link: "",
    versions: [],
  },
  {
    name: "pug",
    link: "",
    versions: [],
  },
  {
    name: "pwa",
    link: "",
    versions: [],
  },
  {
    name: "certbot",
    link: "",
    versions: [],
  },
  {
    name: "tiptap",
    link: "",
    versions: [],
  },
  {
    name: "aws-sdk",
    link: "",
    versions: [],
  },
  {
    name: "jsonwebtoken",
    link: "",
    versions: [],
  },
  {
    name: "node-telegram-bot-api",
    link: "",
    versions: [],
  },
  {
    name: "reactflow",
    link: "",
    versions: [],
  },
  {
    name: "gulp",
    link: "",
    versions: [],
  },
  {
    name: "webpack",
    link: "",
    versions: [],
  },
  {
    name: "sass",
    link: "",
    versions: [],
  },
  {
    name: "css",
    link: "",
    versions: [],
  },
  {
    name: "lottie-web",
    link: "",
    versions: [],
  },
  {
    name: "three.js",
    link: "",
    versions: [],
  },
  {
    name: "postgres",
    link: "",
    versions: [],
  },
  {
    name: "mongodb",
    link: "",
    versions: [],
  },
  {
    name: "express.js",
    link: "",
    versions: [],
  },
  {
    name: "nest.js",
    link: "",
    versions: [],
  },
  {
    name: "pm2",
    link: "",
    versions: [],
  },
  {
    name: "redux",
    link: "",
    versions: [],
  },
  {
    name: "postcss",
    link: "",
    versions: [],
  },
  {
    name: "konva",
    link: "",
    versions: [],
  },
  {
    name: "regex",
    link: "",
    versions: [],
  },
  {
    name: "html",
    link: "",
    versions: [],
  },
  {
    name: "embla-carousel",
    link: "",
    versions: [],
  },
  {
    name: "validator",
    link: "",
    versions: [],
  },
  {
    name: "swiper",
    link: "",
    versions: [],
  },
];
